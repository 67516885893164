import { FrameModules } from 'Shared/frame_modules';
import NewsLetter from 'Shared/newsletter';

FrameModules.add('NewsletterFrameModule', () => {
   new RightToRepairNewsLetter();
});

class RightToRepairNewsLetter extends NewsLetter {
   constructor() {
      const newsletterDiv = document.querySelector('#rightToRepairNewsletterDiv');
      super(newsletterDiv);
   }

   _getEl() {
      return this.el;
   }

   _getIconEl() {
      return document.querySelector('#rightToRepairNewsletterIcon');
   }

   _getTextEl() {
      return document.querySelector('#rightToRepairNewsletterText');
   }

   _getEmailEl() {
      return document.querySelector('#rightToRepairNewsletterEmailInput');
   }

   _getForm() {
      return document.querySelector('#rightToRepairNewsletterForm');
   }

   _getEmailSuccessIcon() {
      return 'fa fa-check-circle';
   }

   _getEmailErrorIcon() {
      return 'fa fa-times';
   }

   _getRecaptchaKey() {
      return this._getForm().get('data-sitekey');
   }

   _getRecaptchaPlaceholder() {
      return document.querySelector('#rightToRepairNewsletter-recaptcha');
   }

   _getEmailSuccessText() {
      return _js('Thanks for joining the Right to Repair movement!');
   }

   _getEmailDefaultText() {
      return _js('Keep up to date by signing up for our newsletter:');
   }
}
